import './whatsAppExample.scss';
import React, { useContext, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { StoreContext } from '../../store/store';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { WhatsApp } from '@mui/icons-material';

export const WhatsAppExample  = ({watchForm, host}) => {
	const { workspace } = useContext(StoreContext);
	const baseUrl = host;

	const img = useMemo(() => {
		if (!watchForm?.image) return null;
		if (typeof watchForm.image === "string") return watchForm.image;
		if (watchForm.image.mediaPath) return watchForm.image.mediaPath;
		return Array.from(watchForm.image)[0];
	}, [watchForm.image]);

	const video = useMemo(() => {
		if (!watchForm?.video) return null;
		if (typeof watchForm.video === "string") return watchForm.video;
		if (watchForm.video.mediaPath) return watchForm.video.mediaPath;
		return Array.from(watchForm.video)[0];
	}, [watchForm.video]);

	const handleWhatsAppForm = (content) => {
		if (!content) return "";
        return content
			.replace(/(^|\s)\*(?!\s)([^\s*].*?[^\s*])\*(?!\S)/g, '$1<strong>$2</strong>')
			.replace(/(^|\s)_(?!\s)([^\s_].*?[^\s_])_(?!\S)/g, '$1<em>$2</em>')
			.replace(/\n/g, '<br>'); 
	};

	const getCarrouselImage = useCallback((value) => {
			if (!value) return `${baseUrl}${value}`;
			// base64 string
			if (typeof value === 'string' && value.startsWith('data:image')) return value;
			if (value.type && value.type.includes("image")) {
				return value.size ? URL.createObjectURL(value) : `${baseUrl}${value.url}`;
			}
			return `${baseUrl}${value}`;
	}, []);

	const getMedia = useCallback((value) => {
			if (!value) return `${baseUrl}${value}`;
			if (typeof value === 'string') return value;
			if (value.type && value.type.includes("image")) {
				return value.size ? URL.createObjectURL(value) : `${baseUrl}${value.url}`;
			}
			return `${baseUrl}${value}`;
	}, []);

	const getVideo = useCallback((value) => {
			if (!value) return `${baseUrl}${value}`;
			if (typeof value === 'string') return value;
			if (value.type && value.type.includes("video")) {
				return value.size ? URL.createObjectURL(value) : `${baseUrl}${value.url}`;
			}
			return `${baseUrl}${value}`;
	}, []);
	
	return (
		<div className="whatsapp-chat">

			<div className="chat-header">
				<p> {workspace.workspaceTitle} </p>
			</div>

			<div className="chat-messages">

				<div className="contentMessage">

					{watchForm?.selectTitle?.id === 2 && <div className="title">{watchForm?.title}</div>}

					{watchForm?.selectTitle?.id === 3 && (
						<div className="image">
							{watchForm?.image && img != null ? (
								<div className="upload-img">
									<img
										src={getMedia(img)}
										alt=""
									/>
								</div>
							) : watchForm?.video && video != null ? (
								<div className="video-container">
									<video controls className="styled-video">
										<source
										src={getVideo(video)}
										type={video.type}
										/>
										Your browser does not support the video tag.
									</video>
								</div>
							) : null}
						</div>
					)}
	
					<div className="message"  dangerouslySetInnerHTML={{ __html: handleWhatsAppForm(watchForm?.content) }}></div>
					<div className="footer">{watchForm?.footer}</div>
				</div>

				<div className="carrousel">
					<Swiper spaceBetween={5} slidesPerView={1.25}>
						{watchForm?.carrouselItems?.map((button, index) => (
							<SwiperSlide>
								<div className="carrousel-item">
									<img
										src={getCarrouselImage(button.media)}
										alt=""
									/>
									<div className="carrousel-content" dangerouslySetInnerHTML={{ __html: button?.content }}></div>
									<div className="actions">
										{button?.listButtons?.map((button, index) => (
											button.type === "phone" ? (
												<div className="button" key={index}>
													<a href={`tel:${button?.phoneNumber}`} target="_blank" rel="noreferrer">
														<FontAwesomeIcon icon={faPhone} />
														{button?.text}
													</a>
												</div>
											) : (
												<div className="button" key={index}>
													<a href={button?.url} target="_blank" rel="noreferrer">
														<FontAwesomeIcon icon={faExternalLinkAlt} />
														{button?.text}
													</a>
												</div>
											)
										))}
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<div className="button-list">

					{watchForm?.listButtons?.map((button, index) => {
						if (button.type === "web") {
							return (
							<div className="button" key={index}>
								<a href={button?.url} target="_blank" rel="noreferrer">
								<FontAwesomeIcon icon={faExternalLinkAlt} />
								{button?.text}
								</a>
							</div>
							);
						} else if (button.type === "phone") {
							return (
							<div className="button" key={index}>
								<a href={`tel:${button?.phoneNumber}`} target="_blank" rel="noreferrer">
								<FontAwesomeIcon icon={faPhone} />
								{button?.text}
								</a>
							</div>
							);
						} else if (button.type === "stopMarket") {
							return (
								<div className="button" key={index}>
									Arretez les promotions
								</div>
							);
						} else {
							return null;
						}
					})}

				</div>
				
			</div>
		</div>
	);
};
