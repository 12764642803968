import imageDefault from '../../../assets/images/image-default.png';
import videoDefault from '../../../assets/images/video-default.png';
import { useState, useEffect } from "react"
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { FormAutocomplete } from "../../../components/UI/Form/FormAutocomplete";
import { FormImgUpload } from "../../../components/UI/Form/FormImgUpload";
import { FormVideoUpload } from "../../../components/UI/Form/FormVideoUpload";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

export const Title  = (props) => {
    const { control, watch, setValue, defaultMediaType = '', defaultTitle = '' } = props;
    const [selectedTitle, setSelectedTitle] = useState(defaultTitle);
    const [selectedTypeMedia, setSelectedTypeMedia] = useState(defaultMediaType);

    useEffect(() => {
        if (defaultMediaType) {
            setSelectedTypeMedia(defaultMediaType);
        }
        if (defaultTitle) {
            setSelectedTitle(defaultTitle);
        }
    }, [defaultMediaType, defaultTitle]);

    const listTitle = [
        { id: 1, title: "Aucun" },
        { id: 2, title: "Texte" },
        { id: 3, title: "Contenu multimédia" }
    ];

    const checkEmoji = (value) => {
        const regex = /[\p{Emoji}]/gu;
        return !regex.test(value);
    };

    const handleSelectTitleChange = (event) => {
        setSelectedTitle(event.title);

        if (event.title === "Texte" || event.title === "Aucun" || event.title === "") {
            setSelectedTypeMedia('');
            setValue('image', null);
        }

        if (event.title === "Contenu multimédia" || event.title === "Aucun" || event.title === "")
            setValue('title', '');
    };

    const handleSelectMedia = (type) => {
        setSelectedTypeMedia(type);
    };

	return (
        <div className="title">
            <div className="info-form">
                <p className="title">
                    Titre ou contenu multimédia <span>Facultatif</span> 
                    <Tooltip title="Ajoutez un titre ou choisissez quel type de média vous utiliserez pour ce titre. Les emojis ne sont pas acceptés">
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </p>
            </div>

            <div className="form-group">
                <FormAutocomplete
                    className="selectTitle"
                    name="selectTitle"
                    control={control}
                    options={listTitle}
                    getOptionLabel={option => option.title}
                    value={selectedTitle} 
                    onChangeCustom={(selectedOption) => {
                        handleSelectTitleChange(selectedOption);
                    }}
                    renderOption={(props, option) =>
                        <li {...props} key={option.id}>
                            {option.title}
                        </li>
                    }
                />

                {selectedTitle === "Texte" && (
                    <FormInputText 
                        name="title" 
                        control={control}
                        maxLength={60}
                        label="Votre titre"
                        rules={{ validate: value => checkEmoji(value) || "N'ajoutez pas d'émojis dans la zone de texte", required: true}}
                    />
                )}
            </div>

            {selectedTitle === "Contenu multimédia" && (
                <div className="listMedia">
                    <div className= {selectedTypeMedia === "image" ? "media active" : "media"} onClick={() => handleSelectMedia("image")}>
                        <img src={imageDefault} alt="default" />
                        <p>Image</p>
                    </div>
                    <div className= {selectedTypeMedia === "video" ? "media active" : "media"} onClick={() => handleSelectMedia("video")}>
                        <img src={videoDefault} alt="default" />
                        <p>Video</p>
                    </div>
                </div>
            )}

            
            {selectedTypeMedia === "image" && (
                <div className="info-media">
                    <p className="title">Sélectionnez une image</p>
                    <FormImgUpload
                        name="image"
                        control={control}
                        rules={{ required: false }}
                        label="Image *"
                        image={watch("image")}
                    />
                </div>
            )}

            {selectedTypeMedia === "video" && (
                <div className="info-media">
                    <p className="title">Séléctionnez une video</p>
                    <FormVideoUpload
                        name="video"
                        control={control}
                        rules={{ required: false }}
                        label="Video *"
                        video={watch("video")}
                    />
                </div>
            )}

        </div>
	);
};
