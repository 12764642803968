import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// Components
import ClipLoader from "react-spinners/ClipLoader";
import { FormButtonSubmit } from "../../components/UI/Form/FormButtonSubmit";
import { Base } from "../../components/Template/Base/Base";
import { Title } from "../../components/Template/Title/Title";
import { Carrousel } from "../../components/Template/Carrousel/Carrousel";
import { Editeur } from "../../components/Template/Editeur/Editeur";
import { Footer } from "../../components/Template/Footer/Footer";
import { ListButtonAction } from "../../components/Template/Button/ListButtonAction";
import { WhatsAppExample } from "../../components/WhatsAppExample/WhatsAppExample";
import { Toastify } from "../../components/UI/Toastify/Toastify";

// Services
import { createTemplate, getTemplate, updateTemplate } from '../../store/index.service';

// Utils
import { convertToBase64 } from '../../utils/helpers';

// Assets
import iconDanger from '../../assets/images/icons/icon-danger.svg';

// Styles
import './template.scss';

export const CreateTemplate = ({
    isEditing = false,
}) => {
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
    } = useForm();
    const { errors } = useFormState({ control });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const watchForm = watch();

    const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);

    const {
        data: templateData,
    } = useQuery({
        queryKey: ['get-template', id],
        queryFn: () => getTemplate(id),
        enabled: isEditing,
    });

    useEffect(() => {
        if (isEditing && templateData?.data) {
            Object.entries(templateData.data).forEach(([key, value]) => {
                setValue(key, value);
            });
            setValue('isDraft', false);
        }
    }, [templateData]);

    const onSubmit = async (data) => {
        setLoading(true);
        data['language'] = "en_US";

        if (data.content.replace(/<\/?[^>]+(>|$)/g, "").length > 1020) {
            Toastify("toMuchText", "error");
            setLoading(false);
            return;
        }
    
        // Attendre la conversion en base64 pour l'image si nécessaire
        if (data['selectTitle']) {
            if(data['selectTitle'].id === 3 && (data.image || data.video)) {
                try {

                    if (data.video && typeof data.video !== 'string') {
                        const videoBase64 = await convertToBase64(data.video[0]);
                        data['video'] = videoBase64;
                    }
                    
                    if (data.image && typeof data.image !== 'string') {
                        const imageBase64 = await convertToBase64(data.image[0]);
                        data['image'] = imageBase64;
                    }
                } catch (error) {
                    setLoading(false);
                    Toastify("error", "error", { detail: "Problème lors de la conversion de l'image" });
                    return;
                }
            }
        }

        // Convertir les images du carrousel en Base64
        if (data['carrouselItems']) {
            for (let i = 0; i < data['carrouselItems'].length; i++) {
                const media = data['carrouselItems'][i].media;

                // Si `media` est un objet `File`, on le convertit en Base64
                if (media instanceof File || media instanceof Blob) {
                    try {
                        const imageBase64 = await convertToBase64(media);
                        data['carrouselItems'][i].media = imageBase64;
                    } catch (error) {
                        setLoading(false);
                        Toastify("error", "error", { detail: "Problème lors de la conversion des images" });
                        return;
                    }
                }
            }
        }

        try {
            if (isEditing) {
                await updateTemplate(data, id);
                Toastify("updateTemplate", "success", { name: data.name });
            } else {
                await createTemplate(data);
                Toastify(data.isDraft ? 'draftTemplate' : 'createTemplate', "success", { name: data.name });
            }
            navigate('/template');
        } catch (err) {
            console.error("Erreur lors de la création du template", err);
            if (err.response && err.response.status === 410)
                Toastify("errorTemplateName", "error");
            if (err.response && err.response.status === 409)
                Toastify("error", "error");
        } finally {
            setLoading(false);
        }
    }

    const handleDraftSubmit = () => {
        setIsSubmittingDraft(true);
        handleSubmit((data) => onSubmit({ ...data, isDraft: true }))();
    }

    return (
        <div className="template">
            <div className="container">

                <div className="content">

                    <div className="info-meta">
                        <Link to="/faq">
                            <img src={iconDanger} alt="danger" />
                            Note : Pour éviter d'être BAN de Metas aller voir la <span>FAQ</span>
                        </Link>
                    </div>

                    <div className="title-page">
                        <h1>Créer un template</h1>
                        <p>Créez un template en remplissant les informations suivantes</p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Base control={control} />

                        <div className="background-white">

                            {watch('typeTemplate')?.id === 1 &&
                                <>
                                    <Title
                                        control={control}
                                        watch={watch}
                                        setValue={setValue}
                                        defaultTitle={templateData?.data?.selectTitle?.title}
                                        defaultMediaType={
                                            typeof templateData?.data?.image === 'string'
                                                ? 'image'
                                                : typeof templateData?.data?.video === 'string'
                                                    ? 'video'
                                                    : undefined
                                        }
                                    />
                                    <Editeur
                                        control={control}
                                        rules={{
                                            required: "Ce champ est obligatoire",
                                        }}
                                        defaultValue={templateData?.data?.content}
                                        nbCaract={1000}
                                        ia
                                        variable
                                    />
                                    <Footer control={control} />
                                    <ListButtonAction control={control} setValue={setValue} getValues={getValues} />
                                </>
                            } 

                            {watch('typeTemplate')?.id === 2 &&
                                <>
                                    <Editeur
                                        control={control}
                                        rules={{
                                            required: "Ce champ est obligatoire",
                                        }}
                                        defaultValue={templateData?.data?.content}
                                        nbCaract={1000}
                                        ia
                                        variable
                                    />
                                    <Carrousel
                                        control={control}
                                        watch={watch}
                                        setValue={setValue}
                                        getValues={getValues}
                                        errors={errors}
                                        defaultValue={templateData?.data}
                                    />
                                </>
                            }
                        </div>
                        {Object.values(errors).length > 0 && (
                            <div className="formHasError">
                                <span>Le formulaire comporte des erreurs</span>
                            </div>
                        )}
                        <p className="creationWarning">
                            ATTENTION ! Si vous choisissez "Créer un template", vous ne pourrez plus le modifier.
                        </p>
                        <div className="btnContainer">
                            <FormButtonSubmit loading={loading && !isSubmittingDraft} label="Créer un template" />
                            <button
                                type="button"
                                className="btn-draft"
                                onClick={handleDraftSubmit}
                            >
                                <span>
                                    Enregistrer un brouillon
                                </span>
                                <ClipLoader
                                    color="var(--violet)"
                                    loading={loading && isSubmittingDraft}
                                    size={20}
                                    cssOverride={{ marginLeft: 5 }}
                                />
                            </button>
                        </div>
                    </form>
     
                </div>

                <div className="aside-right">
                    <WhatsAppExample watchForm={watchForm} />
                </div>
            </div>
        </div>
    )
}