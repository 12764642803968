import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";

export const FormVideoUpload = (props) => {
    const { name, control, label, rules, video, removeMedia, host } = props;
    const baseUrl = host;

    const vid = useMemo(() => {
        if (!video) return null;
        if (typeof video === "string") return video;
        if (video.mediaPath) return video.mediaPath;
        return Array.from(video)[0];
    }, [video]);

    const getVideoSrc = useCallback((value) => {
        if (!value) return `${baseUrl}${value}`;
        if (typeof value === 'string') return value;
        if (value.type && value.type.includes("video")) {
            return value.size ? URL.createObjectURL(value) : `${baseUrl}${value.url}`;
        }
        return `${baseUrl}${value}`;
    }, []);

    return (
    <>
        {vid &&
            <div className="video-container">
                <video
                    controls
                    className="styled-video"
                    style={{ maxHeight: 250 }}
                >
                    <source
                    src={getVideoSrc(vid)}
                    type={vid.type}
                    />
                    Your browser does not support the video tag.
                </video>
                {( !rules || (rules && !rules.required) ) &&
                    <span className="remove-media" onClick={removeMedia}>×</span>
                }
            </div>
        }

        <label>{label}</label>
                                    
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={[]}
            render={({ field: { onChange, ref }, fieldState: { error } }) => <>
                <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        
                        // Vérifie la taille et l'extension du fichier
                        if (file) {
                            const fileSizeLimit = 12 * 1024 * 1024;
                            const fileExtension = file.name.split('.').pop().toLowerCase();
                            
                            if (file.size > fileSizeLimit) {
                                alert("La vidéo ne doit pas dépasser 12 Mo");
                                e.target.value = "";
                                return;
                            }
                            
                            if (fileExtension === "mov") {
                                alert("Le format .mov n'est pas accepté");
                                e.target.value = "";
                                return;
                            }
                        }
                        
                        onChange(e.target.files);
                    }}
                    ref={ref}
                />
                {error && <span className="error">Ce champ est requis</span>}
            </>}
        />
    </>);
};