import './trialOffer.scss';
import { React, useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../store/store';

export const TrialOffer = () => {
    const { setIsTrialOffer, isTrialOffer, workspace } = useContext(StoreContext);
    const [trialMessage, setTrialMessage] = useState('');

    useEffect(() => {
        trialOfferCheck();
    }, [workspace]);

    const trialOfferCheck = () => {
        
        if (workspace.workspaceTutoStep === 1) {
            setIsTrialOffer(true);
            setTrialMessage('Vous devez relier votre votre numéro WhatsApp pour utiliser Whakup');
            return;
        }

        if (workspace.betaEnding !== 0 && workspace.betaEnding !== null) {
            setIsTrialOffer(true);
            setTrialMessage(`Votre offre d'essai se termine dans ${workspace.betaEnding} jours`);
            return;
        }

        if (workspace.betaEnding === 0 && workspace.subscription === null) {
            setIsTrialOffer(true);
            setTrialMessage('Votre forfait est terminé, prenez un nouveau forfait pour débloquer Whakup');
            return;
        }

        setIsTrialOffer(false);
    }

    if (!isTrialOffer) {
        return null;
    }

    return (
        <>
            {isTrialOffer && (
                <div className="trial-offer">
                    <p>{trialMessage}</p>
                </div>
            )}
        </>
    );
};
