import { FormInputText } from "../../../components/UI/Form/FormInputText";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

export const Footer  = (props) => {
    const { control } = props;

    const containsEmoji = (value) => {
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{1F004}\u{1F0CF}]/u;
        return emojiRegex.test(value);
    };

	return (
        <div className="footer">

            <div className="info-form">
                <p className="title">
                    Pied de page <span>Facultatif</span> 
                    <Tooltip title="Les emojis ne sont pas acceptés">
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </p>
            </div>
            
            <div className="form-group">
                <FormInputText 
                    name="footer" 
                    control={control} 
                    maxLength={60} 
                    rules={{ validate: value => !containsEmoji(value) || "N'ajoutez pas d'émojis dans la zone de texte Pied de page", required: false}}
                />
            </div>
            
        </div>
	);
};
