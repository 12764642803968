import api from '../api';

const idWorkspace = localStorage.getItem('idWorkspace');

export const getListTemplate = async (data) => {
    const result = await api.get(`/api/workspace/${idWorkspace}/template`, {
        params: data
    });
    return(result.data);
}

export const getTemplate = async (id) => {
    const result = await api.get(`/api/workspace/${idWorkspace}/template/${id}` , {});
    return(result.data);
}

export const createTemplate = (data) => api.post(`/api/workspace/${idWorkspace}/template`, data);

export const updateTemplate = (data, id) => api.post(`/api/workspace/${idWorkspace}/template/${id}`, data);

export const deleteTemplate= (id) => api.delete(`/api/workspace/${idWorkspace}/template/${id}`, {});

export const duplicateTemplate= (id) => api.post(`/api/workspace/${idWorkspace}/duplicate/template/${id}`, {});