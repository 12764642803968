import "./contentTypeTemplate.scss";
import React from 'react';
import { sanitizeHtml, formatWhatsAppText } from "../../../../utils/helpers";

export const ContentTypeTemplate = ({content}) => {

    const formattedContent = (item) => {
        let formatText = formatWhatsAppText(sanitizeHtml(item.text));

        if(item.example && item.example.length > 0) {
            item.example.forEach((value, index) => {
                console.log(index);
                const regex = new RegExp(`{{1}}`, 'g');
                formatText = formatText.replace(regex, `<strong>yes</strong>`);
            });
        }

        return formatText;
    }

    return (
        <div className="messageContent">

            {content.components.map((item, index) => {
                return (
                    <div key={index} className="template-header">
                        {item.type === 'HEADER' && (
                            <h3>{item.text}</h3>
                        )}
                    </div>
                )
            })}

            {content.components.map((item, index) => {
                return (
                    <div key={index} className="template-body">
                        {item.type === 'BODY' && (
                            <div dangerouslySetInnerHTML={{ __html: formattedContent(item) }}></div>
                        )}
                    </div>
                )
            })}

            {content.components.map((item, index) => {
                return (
                    <div key={index} className="template-footer">
                        {item.type === 'FOOTER' && (
                            <p>{item.text}</p>
                        )}
                    </div>
                )
            })}

            {content.components.map((item, index) => {
                return (
                    <div key={index} className="template-button">
                        {item.type === 'BUTTONS' && item.buttons.map((button, idx) => {
                                return (
                                    <button key={idx} className="button">{button.text}</button>
                                )
                            }
                        )}
                    </div>
                )
            })}

        </div>
    );


};

