import './step.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';

export const Step = (props) => {
    const { type, title, tutoStepNumber, content, contentDone, contentWaitingStep1, contentWaitingStep2, contentWaitingStep3, contentWaitingStep4, btnTitle, btnTitleDone, step, btnUrl } = props;

    const stepInProgress = tutoStepNumber + 1;

    const stepContents = {
        1: contentWaitingStep1,
        2: contentWaitingStep2,
        3: contentWaitingStep3,
        4: contentWaitingStep4,
    };

    return (
        <div className={`step ${type || ""}`}>
            
            <div className="step-title">
                <div className="step-number">
                    {type !== "done" && step} 
                </div>
                <p className="step-name">{title}</p>
            </div>

            <div className="step-content">
                {type === "done" && <p>{contentDone}</p>}
                {type === "inProgress" && <p>{content}</p>}
                {type === "disabled" && <p>{stepContents[stepInProgress]} </p>}

                {type === "done" && 
                    <div className="step-link">
                        <div className="step-link-div">
                            <FontAwesomeIcon icon={faCheck} />
                            <span>
                                {btnTitleDone}
                            </span>
                        </div>
                    </div>
                }

                { type !== "done" &&  
                    <Link to={btnUrl} className="step-link">
                        <div className="step-link-div">

                            {step === "2" && <div className="icon-plus"></div> }
                            {step === "3" && <div className="icon-import"></div> }
                            {step === "4" && <div className="icon-campaign"></div> }

                            <span>
                                {type !== "done" && btnTitle}
                            </span>
                        </div>
                    </Link>
                }

            </div>
        </div>
  	);
} 