import parse from "html-react-parser";
import DOMPurify from "dompurify";

const sanitizeHtml = (html = "") => {
    return parse(DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ['b', 'i', 'a', 'br', 'p', 'span', 'div'],
        ALLOWED_ATTR: ['style', 'target', 'href']
    }));
}

const formatPrice = (priceInCentimes) => {
    const priceInEuros = priceInCentimes / 100;
    return priceInEuros.toFixed(2).replace(".", ",") + " €";
}

const formatDate = (date) => {
    const currentDate = new Date(date);
    
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
  
    return currentDate.toLocaleDateString('fr-FR', options);
}

function formatWhatsAppText(text) {

    // Vérifier si text est un tableau et s'il est vide
    if (Array.isArray(text) && text.length === 0) return '';

    // Vérifier si text est une chaîne vide ou non défini
    if (!text) return '';

    let formattedText = text
      .replace(/\*([^*]+)\*/g, '<strong>$1</strong>') // gras
      .replace(/_([^_]+)_/g, '<em>$1</em>'); // italique

    // Remplacer les sauts de ligne par des balises <br />
    formattedText = formattedText.replace(/\n/g, '<br />');

    return formattedText;
}

function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (file && file instanceof File) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.onerror = (error) => reject(error);
        } else {
            reject("Le fichier fourni n'est pas un objet de type File.");
        }
    });
};

const cn = (...args) => args.filter(Boolean).join(' ');

const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, '');
 
export {
    sanitizeHtml,
    formatPrice,
    formatDate,
    formatWhatsAppText,
    truncateText,
    convertToBase64,
    cn,
    removeHtmlTags,
};