import { useCallback } from 'react';
import './buttonAction.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { useFormState, useForm } from 'react-hook-form';

export const ButtonCallActionPhone  = (props) => {
    const { control, index, remove, name, deleteTooltip } = props;

    const handleRemove = useCallback(() => {
        remove(index);
    }, [remove, index]);

    const phoneNumberPattern = /^\+\d{1,}$/; // Expression régulière pour vérifier le numéro de téléphone

    return (
        <div className="buttonCallActionPhone">
            <div className="containerButton">
                <div className="buttonContainer">
                    <p>Texte du bouton</p>
                    <FormInputText
                        control={control}
                        name={`${name}[${index}].text`}
                        maxLength={25}
                        rules={{ required: "Ce champ est obligatoire" }}
                    />
                </div>

                <div className="buttonContainer">
                    <p>Numéro de téléphone</p>
                    <FormInputText
                        control={control}
                        name={`${name}[${index}].phoneNumber`}
                        maxLength={20}
                        placeholder="+33123456789"
                        rules={{
                            required: "Ce champ est obligatoire",
                            validate: value => phoneNumberPattern.test(value) || "Le numéro de téléphone doit commencer par un indicatif, par exemple : +33123456789"
                        }}
                    />
                </div>
            </div>
            {deleteTooltip ? (
                <Tooltip title={deleteTooltip}>
                    <div className="deleteBtn" onClick={handleRemove}>
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                </Tooltip>
            ) : (
                <div className="deleteBtn" onClick={handleRemove}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            )}
        </div>
    );
};
