import React, { useState, useEffect, useRef } from 'react';
import { Controller } from "react-hook-form";

import styles from './Editeur.module.scss';
import iconIa from '../../../assets/images/icons/icon-ia.svg';
import iconEmoji from '../../../assets/images/icons/icon-emoji.png';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

// Services
import { getFieldCustom } from '../../../store/index.service';

// Components
import { PopupChatGpt } from '../../UI/Popup/PopupChatGpt';
import { UpgradePlan } from '../../UI/Popup/UpgradePlan';
import { Popup } from "../../UI/Popup/Popup";


const Toolbar = ({ 
        onApplyMarkdown, 
        onOpenChatGptPopup, 
        onOpenEmojiPicker, 
        onToggleVariableMenu, 
        characterLimit, 
        countCharacters,
        isVariablesMenuVisible,
        listFieldCustom,
        handleSelectVariable,
        showEmojiPicker,
        handleEmojiSelect
    }) => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.editorActionsLeft}>
                    <button type="button" onClick={() => onApplyMarkdown('*')} className={styles.boldButton}>
                        B
                    </button>
                    <button type="button" onClick={() => onApplyMarkdown('_')} className={styles.italicButton}>
                        i
                    </button>
                    <button type="button" onClick={onOpenEmojiPicker} className={styles.emojiButton}>
                        <img className={styles.iconToolbar} src={iconEmoji} alt="Emoji" />
                    </button>
                    <button type="button" className={styles.btnVar} onClick={onToggleVariableMenu}>
                        Insérer une Variable
                    </button>
                </div>
                <div className={styles.editorActionsRight}>
                    <button type="button" onClick={onOpenChatGptPopup} className={styles.chatGptButton}>
                        <img className={styles.iconToolbar} src={iconIa} alt="IA" />
                        Demander à l'IA <span>0/10</span>
                    </button>

                    <div className={styles.charCount}>
                        {countCharacters > characterLimit ? (
                            <><span className={styles.error}>{countCharacters}</span> / {characterLimit}</>
                        ) : (
                            <>{countCharacters} / {characterLimit}</>
                        )}
                    </div>
                </div>

                {showEmojiPicker && (
                    <div className={styles.emojiPicker}>
                        <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                    </div>
                )}

                <VariablesMenu 
                    isVisible={isVariablesMenuVisible}
                    variables={listFieldCustom}
                    onSelectVariable={handleSelectVariable}
                />
            </div>
        );
};

const VariablesMenu = ({ isVisible, variables, onSelectVariable }) => {
    return (
        <div style={{ display: isVisible ? 'block' : 'none' }} className={styles.dropdownVar}>
            {variables.map((variable) => (
                <div key={variable} onClick={() => onSelectVariable(variable)}>
                    {variable}
                </div>
            ))}
        </div>
    );
};

export const Editeur = (props) => {
    const { control, name = "content", rules, nbCaract = 1000, defaultValue } = props;
    const [text, setText] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isVariablesMenuVisible, setIsVariablesMenuVisible] = useState(false);
    const [listFieldCustom, setListFieldCustom] = useState([]);
    const [showPopupUpgradePlan, setShowPopupUpgradePlan] = useState(false);
    const [showPopupChatGpt, setShowPopupChatGpt] = useState(false);

    useEffect(() => {
        getFieldCustom().then((result) => {
            setListFieldCustom(result.data.fieldCustom);
        });
    }, []);

    useEffect(() => {
        if (defaultValue) {
            setText(defaultValue);
        }
    }, [defaultValue]);

    const applyMarkdown = (symbol, onChange) => {
        const id = `markdownEditor-${name}`;
        const selectionStart = document.getElementById(id).selectionStart;
        const selectionEnd = document.getElementById(id).selectionEnd;

        const selectedText = text.substring(selectionStart, selectionEnd);
        const newText = text.substring(0, selectionStart) + symbol + selectedText + symbol + text.substring(selectionEnd);
        
        if (newText.length <= nbCaract) {
            setText(newText);
            onChange(newText);
        }

    };

    const handleEmojiSelect = (emoji, onChange) => {
        const id = `markdownEditor-${name}`;
        const selectionStart = document.getElementById(id).selectionStart;
        const selectionEnd = document.getElementById(id).selectionEnd;

        const newText = text.substring(0, selectionStart) + emoji.native + text.substring(selectionEnd);
        
        if (newText.length <= nbCaract) {
            setText(newText);
            onChange(newText);
        }

        setShowEmojiPicker(false);
    };

    const handleTextChange = (e, onChange) => {
        const newText = e.target.value;
        
        if (newText.length <= nbCaract) {
            setText(newText);
            onChange(newText);
        }
    };

    const handleUseGeneratedContent = (content) => {
        const newText = text + content;
        
        if (newText.length <= nbCaract)
            setText(newText);

        setShowPopupChatGpt(false);
    };

    const handleSelectVariable = (variable, onChange) => {
        const id = `markdownEditor-${name}`;
        const selectionStart = document.getElementById(id).selectionStart;
        const selectionEnd = document.getElementById(id).selectionEnd;

        const newText = text.substring(0, selectionStart) + `{${variable}}` + text.substring(selectionEnd);
        
        if (newText.length <= nbCaract) {
            setText(newText);
            onChange(newText);
        }

        setIsVariablesMenuVisible(false);
    };

    return (
        <div className={styles.editeur}>
            <div className={styles.formGroup}>
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field: { onChange }, fieldState: { error } }) => (
                        <div className={error ? 'error' : undefined}>
                            <Toolbar 
                                onApplyMarkdown={(symbol) => applyMarkdown(symbol, onChange)} 
                                onOpenChatGptPopup={() => setShowPopupChatGpt(true)}
                                onOpenEmojiPicker={() => setShowEmojiPicker(!showEmojiPicker)}
                                onToggleVariableMenu={() => setIsVariablesMenuVisible(!isVariablesMenuVisible)}
                                characterLimit={nbCaract}
                                countCharacters={text.length}
                                isVariablesMenuVisible={isVariablesMenuVisible}
                                listFieldCustom={listFieldCustom}
                                handleSelectVariable={(variable) => handleSelectVariable(variable, onChange)}
                                showEmojiPicker={showEmojiPicker}
                                handleEmojiSelect={(emoji) => handleEmojiSelect(emoji, onChange)}
                            />
                            <textarea
                                id={`markdownEditor-${name}`}
                                className={styles.contentEditable}
                                placeholder="...."
                                value={text}
                                onChange={(e) => handleTextChange(e, onChange)}
                            />
                        </div>
                    )}
                />
            </div>

            {showPopupChatGpt && (
                <Popup closePopup={() => setShowPopupChatGpt(false)} customContent={true}>
                    <PopupChatGpt chatGptText={handleUseGeneratedContent} />
                </Popup>
            )}

            {showPopupUpgradePlan && (
                <Popup closePopup={() => setShowPopupUpgradePlan(false)} customContent={true}>
                    <UpgradePlan />
                </Popup>
            )}
        </div>
    );
};
