import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import { useMutation } from '@tanstack/react-query';

// Components
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { FormInputText } from "../../../components/UI/Form/FormInputText";

// Services
import { signIn, loginUser } from "../../../store/index.service";

// Utils
import { useAppContext } from '../../../store/store';

// Assets
import media from '../../../assets/images/WhatsApp-marketing-campaign-1.jpeg';
import logoGreen from '../../../assets/images/logo-green.svg';

import './login.scss';

// to do
// - module style
// - btn component isLoading

export const Login = () => {
    const { handleSubmit, control } = useForm();
    const { setLoggedIn } = useAppContext();

    const signInMutation = useMutation({
        mutationFn: signIn,
        onSuccess: ({ data }) => {
            Cookies.set('loggedIn', true, { expires: 1 });
            localStorage.setItem('token', data.token);
            setLoggedIn(true);
            loginUser();
        },
    });

    return (
        <div className="login">

            <div className="login-media">
                <img src={media} alt="media" />
            </div>

            <div className="login-form">
                <div className="logo-whakup">
                    <img src={logoGreen} alt="logo" />
                </div>

                <div className="title">
                    <h1>Bienvenue sur Whakup</h1>
                </div>

                <div className="form">
                    <form name="login" className="register-form" onSubmit={handleSubmit(signInMutation.mutate)}>
                        <div className="form-group">
                            <FormInputText
                                name="username"
                                control={control}
                                rules={{ required: true }}
                                label="Email"
                                type="email"
                            />
                        </div>
                        <div className="form-group">
                            <FormInputText
                                name="password"
                                control={control}
                                rules={{ required: true }}
                                label="Mot de passe"
                                type="password"
                            />
                        </div>
                        <div className="form-group">
                            <div className="form-check">
                                <input type="checkbox" id="rememberMe" name="rememberMe" />
                                <label htmlFor="rememberMe">Se souvenir de moi</label>
                            </div>
                            <Link to="/mot-de-passe-oublier">Mot de passe oublier ?</Link>
                        </div>
                        {signInMutation.isError && (
                            <span className='error-msg'>
                                Email ou mot de passe incorrect
                            </span>
                        )}
                        <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                            <ClipLoader
                                color={"#fff"}
                                loading={signInMutation.isPending}
                                size={20}
                                cssOverride={{ marginRight: 5 }}
                            />
                            <span>Se connecter</span>
                        </Button>

                        <div className="form-group">
                            <p className="no-account">
                                <span>Vous n'avez pas de compte ?&nbsp;</span>
                                <Link to="/inscription">Inscrivez-vous</Link>
                            </p>
                        </div>
                    </form>
                </div>

            </div>
        </div>
  	);
} 