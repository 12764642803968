import './tutorial.scss';
import { React, useContext } from 'react';
import { Step } from './Step/Step';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../store/store';
import { Link } from 'react-router-dom';

export const Tutorial = () => {
	const { workspace = {} } = useContext(StoreContext);
	const { t } = useTranslation();
	const tutoStepNumber = parseInt(workspace?.workspaceTutoStep);

	const getStepType = (step) => {
		return step < tutoStepNumber ? 'done' : 
			   step === tutoStepNumber ? 'inProgress' : 
			   'disabled';
	};

	return (
		<div className="tuto">

			{tutoStepNumber === 1 && 
				<>
					<Link to="/compte/meta">
						<div className="meta-tuto">
							<div className="meta-icon"></div>
							<p>META à lier</p>
							<div className="icon-red"></div>
						</div>
					</Link>
				</>
			}

			{tutoStepNumber > 1 &&
				<>
					<Step 
						step="1" 
						type={getStepType(1)} 
						title={t('tuto_one_title')}
						tutoStepNumber={tutoStepNumber}
						content={t('tuto_one_content')} 
						contentDone={t('tuto_one_content_done')} 
						contentWaitingStep1={t('tuto_one_content_waiting_step1')}
						contentWaitingStep2={t('tuto_one_content_waiting_step2')}
						contentWaitingStep3={t('tuto_one_content_waiting_step3')}
						contentWaitingStep4={t('tuto_one_content_waiting_step4')}
						btnTitle={t('tuto_one_btnTitle')}
						btnTitleDone={t('tuto_one_btnTitle_done')}
						btnUrl="/compte/meta"
					/>
					<Step 
						step="2" 
						type={getStepType(2)} 
						title={t('tuto_two_title')} 
						tutoStepNumber={tutoStepNumber}
						content={t('tuto_two_content')} 
						contentDone={t('tuto_two_content_done')} 
						contentWaitingStep1={t('tuto_two_content_waiting_step1')}
						contentWaitingStep2={t('tuto_two_content_waiting_step2')}
						contentWaitingStep3={t('tuto_two_content_waiting_step3')}
						contentWaitingStep4={t('tuto_two_content_waiting_step4')}
						btnTitle={t('tuto_two_btnTitle')}
						btnTitleDone={t('tuto_two_btnTitle_done')} 
						btnUrl="/template"
					/>
					<Step 
						step="3" 
						type={getStepType(3)} 
						title={t('tuto_three_title')} 
						tutoStepNumber={tutoStepNumber}
						content={t('tuto_three_content')} 
						contentDone={t('tuto_three_content_done')} 
						contentWaitingStep1={t('tuto_three_content_waiting_step1')}
						contentWaitingStep2={t('tuto_three_content_waiting_step2')}
						contentWaitingStep3={t('tuto_three_content_waiting_step3')}
						contentWaitingStep4={t('tuto_three_content_waiting_step4')}
						btnTitle={t('tuto_three_btnTitle')}
						btnTitleDone={t('tuto_three_btnTitle_done')} 
						btnUrl="/contact"
					/>
					<Step 
						step="4" 
						type={getStepType(4)} 
						title={t('tuto_four_title')} 
						tutoStepNumber={tutoStepNumber}
						content={t('tuto_four_content')} 
						contentDone={t('tuto_four_content_done')} 
						contentWaitingStep1={t('tuto_four_content_waiting_step1')}
						contentWaitingStep2={t('tuto_four_content_waiting_step2')}
						contentWaitingStep3={t('tuto_four_content_waiting_step3')}
						contentWaitingStep4={t('tuto_four_content_waiting_step4')}
						btnTitle={t('tuto_four_btnTitle')}
						btnTitleDone={t('tuto_four_btnTitle_done')} 
						btnUrl="/campagnes"
					/>
				</>
			}
		</div>
	);
} 