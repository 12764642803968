import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";

export const FormImgUpload = (props) => {

    const { name, control, label, rules, image, removeMedia, host } = props;

    const img = useMemo(() => {
        if (!image) return null;
        if (typeof image === "string") return image;
        if (image.mediaPath) return image.mediaPath;
        return Array.from(image)[0];
    }, [image]);

    const getImgSrc = useCallback((value) => {
        if (!value) return `${baseUrl}${value}`;
        if (typeof value === 'string') return value;
        if (value.type && value.type.includes("image")) {
            return value.size ? URL.createObjectURL(value) : `${baseUrl}${value.url}`;
        }
        return `${baseUrl}${value}`;
    }, []);

    const baseUrl = host;

    return (
    <>
        {img &&
            <div className="upload-img">
                <img
                    src={getImgSrc(img)}
                    alt=""
                />
                {( !rules || (rules && !rules.required) ) &&
                    <span className="remove-media" onClick={removeMedia}>×</span>
                }
            </div>
        }

        <label>{label}</label>
                                    
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={[]}
            render={({ field: { onChange, ref }, fieldState: { error } }) => <>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        
                        // Vérifie la taille et l'extension du fichier
                        if (file) {
                            const fileSizeLimit = 5 * 1024 * 1024;
                            
                            if (file.size > fileSizeLimit) {
                                alert("L'image ne doit pas dépasser 5 Mo");
                                e.target.value = "";
                                return;
                            }
                        
                        }
                        
                        onChange(e.target.files);
                    }}
                    ref={ref}
                />
                {error && <span className="error">Ce champ est requis</span>}
            </>}
        />
    </>);
};