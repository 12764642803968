import React, { useState, useEffect, useRef, useCallback } from 'react';
import Sortable from 'sortablejs';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faGripVertical, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { useFieldArray, useFormState } from 'react-hook-form';

// Components
import { Editeur } from "../Editeur/Editeur";
import { ListButtonAction } from "../Button/ListButtonAction";
import { UploadFile } from './UploadFile';

// Utils
import { removeHtmlTags } from '../../../utils/helpers';

// Assets
import defaultCarrousel from "../../../assets/images/default-carrousel.png";

// Styles
import './carrousel.scss';

export const Carrousel = (props) => {
    const { control, watch, setValue, defaultValue } = props;
    const { errors } = useFormState({ control });
    const sortableRef = useRef(null);
    const { fields: carrouselItems, append, remove, move, update } = useFieldArray({ name: 'carrouselItems', control });

    // Initial state for collapse
    const [openIndexes, setOpenIndexes] = useState([]);

    useEffect(() => {
        if (sortableRef.current) {
            Sortable.create(sortableRef.current, {
                animation: 150,
                handle: '.handle',
                onEnd: (evt) => {
                    const { oldIndex, newIndex } = evt;
                    move(oldIndex, newIndex);
                }
            });
        }
    }, []);

    const toggleCollapse = (index) => {
        setOpenIndexes((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    const addItem = useCallback((event) => {
        event.preventDefault();
        if(carrouselItems.length >= 10) return;

        let listButtonsToAppend = [];
        if (carrouselItems.length > 0 && carrouselItems[0].listButtons?.length > 0) {
            listButtonsToAppend = carrouselItems[0].listButtons;
        }
        append({ 
            content: '',
            media: '',
            listButtons: listButtonsToAppend,
        });
        toggleCollapse(carrouselItems.length);
    }, [carrouselItems, append]);

    const removeItem = useCallback((index) => {
        remove(index);
    }, [remove]);

    const handleAddBtnToAllSlides = useCallback((data) => {
        carrouselItems?.forEach((slide, index) => {
            update(index, {
                ...(watch(`carrouselItems[${index}]`) || {}),
                listButtons: [
                    ...(slide.listButtons || []),
                    data,
                ],
            });
            if (!openIndexes.includes(index)) toggleCollapse(index);
        });
    }, [carrouselItems, openIndexes, toggleCollapse, update, watch]);

    const handleRemoveBtnToAllSlides = useCallback((indexBtn) => {
        carrouselItems?.forEach((slide, index) => {
            const newSlideListButtons = [...(slide.listButtons || [])];
            newSlideListButtons.splice(indexBtn, 1);
            update(index, {
                ...slide,
                listButtons: newSlideListButtons,
            });
        });
    }, [carrouselItems, update]);

    const getCarrouselImage = useCallback((value) => {
        if (!value) return defaultCarrousel;
        // base64 string
        if (typeof value === 'string' && value.startsWith('data:image')) return value;
        return URL.createObjectURL(value);
    }, []);

    return (
        <div className="form-group">
            <div className="carrousel">
                <div className="carrousel-info">
                    <h3>Fiches carrousel  
                        <Tooltip title="Le carrousel est limité à 10 cartes">
                            <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                        </Tooltip>
                    </h3>
                    <p>{carrouselItems.length} sur 10 cartes ajoutées</p>
                </div>

                <div className="carrousel-container" ref={sortableRef}>
                    {carrouselItems.map((item, index) => (
                        <div key={item.id} className="carrousel-item">
                            <div className="item">
                                <FontAwesomeIcon icon={faGripVertical} className="handle" />
                                <div className="media">
                                    <img
                                        src={getCarrouselImage(watch(`carrouselItems[${index}].media`)) }
                                        alt="placeholder"
                                        className="img"
                                    />
                                </div>
                                <div className="carrousel-item-content">
                                    <div className="message" dangerouslySetInnerHTML={{ __html: watch(`carrouselItems[${index}].content`) || 'Contenu de la carte' }}></div>
                                </div>
                                <div className="actions">
                                    <FontAwesomeIcon 
                                        icon={faEdit} 
                                        onClick={() => toggleCollapse(index)} 
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faRemove}
                                        onClick={() => removeItem(index)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>

                            {openIndexes.includes(index) && (
                                <div className="collapse-content">
                                    <div className="collapse-media">
                                        <UploadFile
                                            control={control}
                                            name={`carrouselItems[${index}].media`}
                                            rules={{ required: "Image obligatoire" }}
                                        />
                                        <Editeur
                                            control={control}
                                            watch={watch}
                                            nbCaract={65}
                                            ia={false}
                                            name={`carrouselItems[${index}].content`}
                                            idToolbar={`sliderContent${index}`}
                                            rules={{
                                                required: "Ce champ est obligatoire",
                                                validate: (val) => removeHtmlTags(val).length <= 65 || "Le texte ne doit pas contenir plus de 65 caractères",
                                            }}
                                            defaultValue={defaultValue?.carrouselItems?.[index]?.content}
                                        />
                                    </div>
                                    {errors?.carrouselItems?.[index]?.media && (
                                        <div className="error">
                                            <span>{errors?.carrouselItems?.[index]?.media.message}</span>
                                        </div>
                                    )}
                                    <div className="list-buttons">
                                        <ListButtonAction
                                            control={control}
                                            setValue={setValue}
                                            name={`carrouselItems[${index}].listButtons`}
                                            nomarket
                                            listOnly
                                            deleteTooltip="Supprimer ce bouton le supprimera sur toutes les slides"
                                            handleRemove={handleRemoveBtnToAllSlides}
                                        />
                                    </div>
                                </div>
                            )}

                        </div>
                    ))}
                </div>

                <div className="btn-add">
                    <button onClick={addItem} disabled={carrouselItems.length >= 10}>
                        <FontAwesomeIcon icon={faPlus} /> Ajouter une carte
                    </button>
                </div>
                <ListButtonAction
                    control={control}
                    setValue={setValue}
                    nomarket
                    btnAddOnly
                    btnLabel="+ Ajouter un bouton aux slides"
                    handleAppend={handleAddBtnToAllSlides}
                    disabled={carrouselItems?.length < 1}
                />
            </div>
        </div>
    );
};
