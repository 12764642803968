import './campaign.scss';
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom';
import { createCampaign, getListTemplate, getListGroups } from '../../store/index.service';
import { FormInputText } from "../../components/UI/Form/FormInputText";
import { FormButtonSubmit } from "../../components/UI/Form/FormButtonSubmit";
import { FormAutocomplete } from "../../components/UI/Form/FormAutocomplete";
import { WhatsAppExampleCampaign } from "../../components/WhatsAppExample/WhatsAppExampleCampaign";
import { ConfirmCampaign } from '../../components/UI/Popup/ConfirmCampaign';
import { UpgradePlan } from "../../components/UI/Popup/UpgradePlan";
import { Popup } from "../../components/UI/Popup/Popup";
import { Toastify } from "../../components/UI/Toastify/Toastify";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

export const CreateCampaign = () => {
    const navigate = useNavigate();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            sendProgrammation: { id: 2, title: "Immédiat" }
        }
    });
    const [loading, setLoading] = useState(false);
    const [setErrorMsg] = useState("");
    const [listTemplate, setListTemplate] = useState([]);
    const [listGroups, setListGroups] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [showPopupConfirmCapaign, setShowPopupConfirmCapaign] = useState({ show: false });
    const [showPopupUpgradePlan, setShowPopupUpgradePlan] = useState({show: false});
    const [selectedSend, setSelectedSend] = useState("Immédiat");

    const onSubmit = async (data, status) => {
        setLoading(true);
        data.status = status;

        try {
            await createCampaign(data);
            Toastify("createCampaign", "success", { name: data.title });
            navigate('/campagnes');
        } catch (err) {
            setLoading(false);

            if (err.response.status === 409) {
                setErrorMsg(err.response.data.result);
                Toastify("error", "error");
            } else if(err.response.status === 403) {
                setShowPopupUpgradePlan({show: true});
            } else {
                Toastify("error", "error");
                setErrorMsg("Une erreur est survenue");
            }
        }
    };

    useEffect(() => {
        const paramsData = {
            status: true
        };

        getListTemplate(paramsData).then((result) => {
            setListTemplate(result);
        });

        getListGroups().then((result) => {
            setListGroups(result);
        });
    }, []);

    const handleSelectProgramChange = (selectedOption) => {
        if (selectedOption.title === "Programmé") {
            setSelectedSend("Programmé");
        } else {
            setSelectedSend("Immédiat");
        }
    };

    const handleSaveAsDraft = (data) => {
        onSubmit(data, 'Brouillon');
    };

    const handleSaveAndSend = (data) => {
        setShowPopupConfirmCapaign({ show: true, data });
    };

    const handleConfirmSend = (data) => {
        setShowPopupConfirmCapaign({ show: false });
        onSubmit(data, 'À envoyer');
    };

    return (
        <div className="create-campaign">
            <div className="container">
                <div className="title-page">
                    <h1>Créer une campagne
                        <Tooltip title="Vous devez vérifier votre entreprise sur Meta pour envoyer plus de 250 message">
                            <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                        </Tooltip>
                    </h1>
                    <p>Envoyer votre campagne</p>
                </div>


                <form>
                    <div className="form-group">
                        <FormInputText name="title" control={control} rules={{ required: true }} label="Titre de votre campagne *" />
                    </div>
                    <div className="form-group">
                        <FormAutocomplete
                            name="groups"
                            control={control}
                            label="Groupe"
                            options={listGroups}
                            getOptionLabel={option => option.title}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    {option.title} - {option.nbContact} Contacts
                                </li>
                            }
                            clearable={true}
                            multiple={true}
                        />
                    </div>
                    <div className="form-group">
                        <FormAutocomplete
                            name="template"
                            control={control}
                            value={selectedTemplate}
                            label="Template"
                            rules={{ required: true }}
                            options={listTemplate}
                            getOptionLabel={option => option.name}
                            onChangeCustom={(newValue) => {
                                setSelectedTemplate(newValue);
                            }}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            }
                            clearable={true}
                        />
                    </div>
                    <div className="form-group">

                        <FormAutocomplete
                            name="sendProgrammation"
                            control={control}
                            label="Programmation d'envoi"
                            options={[
                                { id: 1, title: "Programmé" },
                                { id: 2, title: "Immédiat" }
                            ]}
                            getOptionLabel={option => option.title}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    {option.title}
                                </li>
                            }
                            defaultValue={{ id: 2, title: "Immédiat" }}
                            onChangeCustom={(selectedOption) => {
                                handleSelectProgramChange(selectedOption);
                            }}
                        />

                        {selectedSend === "Programmé" && (
                            <>
                                <FormInputText name="dateProgrammation" control={control} rules={{ required: true }} type="date" />
                                <FormInputText name="timeProgrammation" control={control} rules={{ required: true }} type="time" />
                            </>
                        )}
                    </div>

                    <div className="form-group">
                        <div className="list-btn">
                            <FormButtonSubmit
                                loading={loading}
                                label="Créer une campagne"
                                onClick={handleSubmit(handleSaveAndSend)}
                                type="button"
                            />
                            <FormButtonSubmit
                                loading={loading}
                                label="Enregistrer en tant que brouillon"
                                onClick={handleSubmit(handleSaveAsDraft)}
                                type="button"
                                className="btn-secondary"
                            />
                        </div>
                    </div>
                    <p className="infoFees">Des frais Meta vont s'appliquer pour votre campagne. <br />Chaque message envoyé dans le cadre d'une campagne est soumis au tarif de 0,1186 € par message. <br /><Link to="/faq">En savoir plus</Link></p>
                </form>
            </div>

            <div className="aside-right">
                <div className="apercu">
                    <h2>Aperçu de la campagne</h2>
                    <p>Visionnez le Template que vous allez envoyer.</p>
                </div>
                <WhatsAppExampleCampaign template={selectedTemplate} />
            </div>

            {showPopupConfirmCapaign.show && (
                <Popup closePopup={() => setShowPopupConfirmCapaign({ show: false })} customContent={true}>
                    <ConfirmCampaign
                        msg=""
                        handleConfirm={() => handleConfirmSend(showPopupConfirmCapaign.data)}
                    />
                </Popup>
            )}

            { showPopupUpgradePlan.show &&
                <Popup closePopup={() => setShowPopupUpgradePlan({ show: false })} customContent={true}>
                    <UpgradePlan />
                </Popup>
            }
        </div>
    );
};
