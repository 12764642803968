import './privacyPolicy.scss';
import { useContext } from 'react';
import { NavigationAccount } from "../../components/NavigationAccount/NavigationAccount";
import { StoreContext } from '../../store/store';

export const PrivacyPolicy = () => {
    const { loggedIn } = useContext(StoreContext);

    return (
        <div className="politique">

            {loggedIn && <NavigationAccount active="cgu" /> }

            <div className="cgu">

                <h1>Politique de Confidentialité de UXIA</h1>

                <h3>1. Introduction</h3>

                <p>UXIA, située au 49 RUE DE PONTHIEU 75008 PARIS, Siren 848276135, est déterminée à protéger et à respecter votre vie privée. Nous comprenons l'importance de la confidentialité des données et nous nous engageons à gérer vos données personnelles en toute sécurité et transparence.</p>

                <h3>2. Données collectées</h3>

                <p>
                    Nous pouvons collecter, utiliser, stocker et transférer différents types de données personnelles vous concernant, qui ont été regroupées comme suit : <br />
                    Identité et coordonnées : nom, adresse e-mail, numéro de téléphone, etc.<br />
                    Données techniques : adresse IP, vos identifiants de connexion, type et version du navigateur, etc.<br />
                    Données d'utilisation : informations sur la manière dont vous utilisez notre site web, produits et services.
                </p>

                <h3>3. Utilisation des données</h3>

                <p>
                    Nous utiliserons vos données personnelles dans diverses situations, notamment : <br />
                    Pour vous fournir nos produits et services. <br />
                    Pour vous envoyer des communications marketing et promotionnelles. <br />
                    Pour vous informer des modifications de nos services. <br />
                    Pour améliorer notre site web et nos produits/services.
                </p>

                <p>
                    Le Client reconnaît et accepte qu'il est seul responsable de la collecte, de la gestion et de l'utilisation des bases de données qu'il importe et utilise au sein de l'outil fourni par 
                    WHAKUP dans le cadre de ses campagnes de prospection commerciale. Le Client s'engage à utiliser exclusivement des bases de données collectées 
                    de manière légale et conforme aux exigences du Règlement Général sur la Protection des Données (RGPD) et des autres lois applicables, en particulier en ce qui 
                    concerne l'obtention des consentements préalables (opt-in) requis pour l'envoi de communications commerciales.
                </p>
                <p>
                    WHAKUP, en sa qualité de sous-traitant, ne procède à aucune vérification quant à la légalité, l'origine ou la conformité des bases de données fournies 
                    et utilisées par le Client dans le cadre de l'utilisation de ses services. Il incombe exclusivement au Client de s'assurer que les 
                    destinataires de ses communications ont donné leur consentement explicite et que les données sont traitées dans le respect de la législation en vigueur.
                </p>
                <p>
                    En conséquence, WHAKUP ne saurait être tenue pour responsable des éventuelles violations du RGPD, de la réglementation sur la prospection 
                    commerciale ou de toute autre loi applicable résultant de l'utilisation par le Client de bases de données non conformes. 
                    Le Client s'engage à garantir et indemniser UXIA contre toute réclamation, amende, sanction ou action judiciaire 
                    résultant de l'utilisation illégale des données par le Client.
                </p>

                <p>
                    Le Client s'engage à fournir à WHAKUP, sur demande, toute documentation ou preuve de la conformité des bases de données utilisées, 
                    y compris les preuves de consentement des personnes concernées. Le non-respect de cet engagement pourra entraîner la 
                    suspension ou la résiliation immédiate des services fournis par WHAKUP, sans préavis ni indemnité.
                </p>

                <h3>4. Partage des données</h3>

                <p>
                    Nous pouvons partager vos données personnelles avec des tiers dans certaines circonstances, notamment avec :<br />
                    Les prestataires de services qui fournissent des services de TI et de système.<br />
                    Les autorités réglementaires ou autres autorités.
                </p>

                <h3>5. Sécurité des données</h3>

                <p>
                    Nous avons mis en place des mesures de sécurité pour empêcher que vos données personnelles ne soient accidentellement perdues, utilisées ou accessibles de manière non autorisée, modifiées ou divulguées.
                </p>

                <h3>6. Vos droits</h3>
                <p>Vous avez des droits en ce qui concerne vos données personnelles, y compris le droit de demander l'accès, la correction, la suppression, la restriction, le transfert, de s'opposer au traitement, à la portabilité des données et (là où le fondement légitime du traitement est le consentement) de retirer le consentement.</p>
                <h3>7. Modifications de la politique de confidentialité</h3>
                <p>Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment, et nous vous fournirons une nouvelle politique de confidentialité lorsque nous apporterons des modifications substantielles.</p>
                <h3>8. Contact</h3>
                <p>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à contact@uxia.com .</p>
            </div>
        </div>
    )
}