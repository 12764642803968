import { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { FormInputText } from "../Form/FormInputText";
import { FormAutocomplete } from "../Form/FormAutocomplete";
import { useForm } from "react-hook-form";
import { getListCountry, createMessageChatGpt } from '../../../store/index.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FormButtonSubmit } from "../Form/FormButtonSubmit";

export const PopupChatGpt = ({ handleClosePopup, chatGptText }) => {
    const { handleSubmit, control } = useForm();
    const [listCountry, setListCountry] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [result, setResult] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getListCountry().then((result) => {
            setListCountry(result);
        });
    }, []);

    const onSubmit = (data) => {
        setLoading(true);
        createMessageChatGpt(data).then((result) => {
            setResult(result.data.content);
            setIsSubmitted(true);
            setLoading(false);
        });
    }

    const handleUseText = () => {
        chatGptText(result);
        handleClosePopup();
    };

    const listTonText = [
        { id: 1, title: "Formel" },
        { id: 2, title: "Amical" },
        { id: 3, title: "Inspirant" },
        { id: 4, title: "Convaincant" },
        { id: 5, title: "Humoristique" },
        { id: 6, title: "Informatif" }
    ];

    const listObjectif = [
        { id: 1, title: "Augmenter la notoriété de la marque" },
        { id: 2, title: "Générer des leads" },
        { id: 3, title: "Lancer un nouveau produit ou service" },
        { id: 4, title: "Stimuler les ventes" },
        { id: 5, title: "Améliorer l'engagement client" },
        { id: 6, title: "Collecter des avis clients" },
        { id: 6, title: "Promouvoir un événement" },
        { id: 6, title: "Fidéliser la clientèle" },
        { id: 6, title: "Informer" }
    ];

    if (isSubmitted) {
        return (
            <div className='popupContentAfterSubmit'>
                
                <div className="chatGptResult">
                    <h3>Résultat</h3>

                    <div className="chatGptResultContent">
                        <article>{result}</article>
                        <FontAwesomeIcon icon={faCopy} onClick={() => {navigator.clipboard.writeText(result)}} />
                    </div>
                </div>

                <div className="btn-container">
                    <Button variant="contained" onClick={handleClosePopup}>Fermer</Button>
                    <Button variant="contained" onClick={handleUseText} >Utiliser le contenu</Button>
                </div>
            </div>
        );
    }

    return (
        <div className='popupCreateHorseQuick chatGptPopup'>
            <p>Demandez à une IA de générer un contenu pour votre campagne</p>

                <div className="form-group">
                    <FormInputText type="textaera" name="content" control={control} rules={{ required: true }} label="Contenu" />
                </div>

                <div className="form-group">
                    <FormAutocomplete
                        name="language"
                        control={control}
                        rules={{ required: true }}
                        label="Langue"
                        options={listCountry}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        }
                        clearable={true}
                    />
                    
                    <FormAutocomplete
                        control={control}
                        name="tonText"
                        label="Ton du texte"
                        rules={{ required: true }}
                        options={listTonText}
                        getOptionLabel={option => option.title}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                {option.title}
                            </li>
                        }
                        clearable={true}
                    />
                </div>

                <div className="form-group">
                    <FormInputText name="cible" control={control} rules={{ required: true }} label="Cible" />
                    <FormInputText type="number" name="nbWord" control={control} 
                      rules={{
                        required: true,
                        max: {
                          value: 250,
                          message: "Le nombre maximum de mots ne peut pas dépasser 250"
                        }
                      }}
                    inputProps={{ max: 250 }} label="Nombre maximum de mots" />
                </div>
                <div className="form-group">
                    <FormAutocomplete
                        name="objectif"
                        control={control}
                        label="Objectif"
                        rules={{ required: true }}
                        options={listObjectif}
                        getOptionLabel={option => option.title}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                {option.title}
                            </li>
                        }
                        clearable={true}
                    />
                </div>

                <div className="form-group">
                    <FormInputText type="textaera" name="companyContent" control={control} rules={{ required: true }} label="Description de votre entreprise" />
                </div>

                <div className="btn-container">
                    <Button variant="contained" onClick={handleClosePopup}>Annuler</Button>
                    <Button variant="contained" onClick={handleSubmit(onSubmit)}>Enregistrer</Button>
                </div>
        </div>
    )
}