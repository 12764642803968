import axios from 'axios';

const Axios = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	timeout: 30000,
});

export default Axios;